import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import TermsConditionsIntroComponent from "@sections/terms-conditions-intro/terms-conditions-intro-component";

export default function TermsAndConditions() {
  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 220 }} mobile={{ margin: 110 }} />

      <TermsConditionsIntroComponent />

      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="TreeTree2 - Termos e condições"
        description="Consulte os termos e condições de utilização do site do TreeTree2."
      />
    </Layout>
  );
}
