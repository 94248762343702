import styled from "styled-components";

export const Container = styled.section``;

export const Content = styled.div`
  max-width: 920px;
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  margin-bottom: 63px;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
`;
